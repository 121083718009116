<template>
  <section id="dashboard">
    <v-container class="fill-height">
      <v-alert
        v-if="!user.verified"
        style="top: 0; margin: 0; position: relative; width: 100%; z-index: 0"
        class="mb-6"
        prominent
        outlined
        type="warning"
      >
        <v-row
          align="center"
          class="flex-wrap"
        >
          <v-col
            cols="12"
            md="8"
            class="mb-2 mb-md-0"
          >
            För att låsa upp ditt konto, vänligen verifiera din e-post genom att
            klicka på mailet som vi har skickat till dig.
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="text-md-right"
          >
            <v-btn
              :loading="verificationLoading"
              @click="sendVerificationEmail"
            >
              Skicka igen
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <h2
        class="text-h5 mb-4"
        v-text="$t('Pages.Dashboard.welcome') + ' ' + user.firstName"
      />
      <v-spacer />
      <v-row v-if="user.userType === 'consultant'">
        <v-col
          v-for="card in consultantCards"
          :key="card.title"
          cols="12"
          sm="12"
          md="4"
        >
          <v-card
            class="neutral rounded-xl"
            :to="card.icon !== 'mdi-credit-card' ? card.to : null"
            :disabled="!user.verified || card.isDisabled"
            @click="
              card.icon === 'mdi-credit-card' ? onPaymentDetailsClick() : null
            "
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title
                  class="text-h6 mb-1"
                  v-text="card.title"
                />
                <v-list-item-subtitle v-text="card.subtitle" />
              </v-list-item-content>
              <v-list-item-avatar
                size="80"
                color="#e2e2e2"
              >
                <v-icon
                  color="neutral"
                  x-large
                >
                  {{ card.icon }}
                </v-icon>
              </v-list-item-avatar>
            </v-list-item>

            <v-card-actions class="justify-center">
              <v-btn
                id="cta-1"
                class="ma-1"
                rounded
                text
                outlined
                :to="card.icon !== 'mdi-credit-card' ? card.to : null"
                :disabled="!user.verified || card.isDisabled"
                @click="
                  card.icon === 'mdi-credit-card'
                    ? onPaymentDetailsClick()
                    : null
                "
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="user.userType === 'customer'">
        <v-col
          v-for="card in customerCards"
          :key="card.title"
          cols="12"
          sm="12"
          md="4"
        >
          <v-card
            class="neutral rounded-xl"
            :to="card.icon !== 'mdi-credit-card' ? card.to : null"
            :disabled="!user.verified || card.isDisabled"
            @click="
              card.icon === 'mdi-credit-card' ? onPaymentDetailsClick() : null
            "
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title
                  class="text-h6 mb-1"
                  v-text="card.title"
                />
                <v-list-item-subtitle v-text="card.subtitle" />
              </v-list-item-content>
              <v-list-item-avatar
                size="80"
                color="#e2e2e2"
              >
                <v-icon
                  color="neutral"
                  x-large
                >
                  {{ card.icon }}
                </v-icon>
              </v-list-item-avatar>
            </v-list-item>

            <v-card-actions class="justify-center">
              <v-btn
                id="cta-1"
                class="ma-1"
                rounded
                text
                outlined
                :to="card.icon !== 'mdi-credit-card' ? card.to : null"
                :disabled="!user.verified || card.isDisabled"
                @click="
                  card.icon === 'mdi-credit-card'
                    ? onPaymentDetailsClick()
                    : null
                "
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          v-for="card in consultantCards"
          :key="card.title"
          cols="12"
          sm="12"
          md="4"
        >
          <v-card
            class="neutral rounded-xl"
            :to="card.to"
            :disabled="!user.verified || card.isDisabled"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title
                  class="text-h6 mb-1"
                  v-text="card.title"
                />
                <v-list-item-subtitle v-text="card.subtitle" />
              </v-list-item-content>
              <v-list-item-avatar
                size="80"
                color="#e2e2e2"
              >
                <v-icon
                  color="neutral"
                  x-large
                >
                  {{ card.icon }}
                </v-icon>
              </v-list-item-avatar>
            </v-list-item>

            <v-card-actions class="justify-center">
              <v-btn
                id="cta-1"
                class="ma-1"
                rounded
                text
                outlined
                :to="card.to"
                :disabled="!user.verified || card.isDisabled"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
// TODO: Determine what items in the tabs

import { mapState } from "vuex";
import {
  GET_COMPANIES,
  GET_PRODUCTS,
  RESEND_EMAIL,
} from "@/store/actions.type";

export default {
  name: "Dashboard",
  data: () => ({
    verificationLoading: false,
  }),
  computed: {
    ...mapState(["user", "products"]),
    consultantCards() {
      return [
        {
          title: this.$t("Navigation.profile"),
          subtitle: this.$t("Pages.Dashboard.c1"),
          icon: "mdi-account",
          color: "primary",
          to: { name: "edit-profile" },
          isDisabled: false,
        },
        {
          title: this.$t("Navigation.offers"),
          subtitle: this.$t("Pages.Dashboard.c3"),
          icon: "mdi-package-variant",
          color: "primary",
          to: { name: "inner-offers" },
          isDisabled: false,
        },
        {
          title: this.$t("Navigation.search"),
          subtitle: this.$t("Pages.Dashboard.c2"),
          icon: "mdi-magnify",
          color: "primary",
          to: { name: "inner-search" },
          isDisabled: false,
        },
        {
          title: this.$t("Pages.Dashboard.contracts"),
          subtitle: this.$t("Pages.Dashboard.c4"),
          icon: "mdi-file-document-outline",
          color: "primary",
          to: { name: "contracts" },
          isDisabled: true,
        },
        {
          title: this.$t("Pages.Dashboard.time_report"),
          subtitle: this.$t("Pages.Dashboard.time_report_subtitle"),
          icon: "mdi-clock-outline",
          color: "primary",
          to: { name: "time-reporting" },
          isDisabled: true,
        },
        {
          title: this.$t("Pages.Dashboard.payment_title"),
          subtitle: this.$t("Pages.Dashboard.payment_subtitle"),
          icon: "mdi-credit-card",
          color: "primary",
          to: { name: "pricing" },
          isDisabled: false,
        },
      ];
    },
    customerCards() {
      return [
        {
          title: this.$t("Navigation.search"),
          subtitle: this.$t("Pages.Dashboard.c2"),
          icon: "mdi-magnify",
          color: "primary",
          to: { name: "inner-search" },
          isDisabled: false,
        },
        {
          title: this.$t("Pages.Dashboard.payment_title"),
          subtitle: this.$t("Pages.Dashboard.payment_subtitle"),
          icon: "mdi-credit-card",
          color: "primary",
          to: { name: "pricing" },
          isDisabled: false,
        },
        {
          title: this.$t("Pages.Dashboard.contracts"),
          subtitle: this.$t("Pages.Dashboard.c4"),
          icon: "mdi-file-document-outline",
          color: "primary",
          to: { name: "contracts" },
          isDisabled: true,
        },
        {
          title: this.$t("Navigation.offers"),
          subtitle: this.$t("Pages.Dashboard.c3"),
          icon: "mdi-package-variant",
          color: "primary",
          to: { name: "inner-offers" },
          isDisabled: false,
        },
        {
          title: this.$t("Navigation.profile"),
          subtitle: this.$t("Pages.Dashboard.c1"),
          icon: "mdi-account",
          color: "primary",
          to: { name: "edit-profile" },
          isDisabled: false,
        },
      ];
    },
  },
  created() {
    if (this.user.userType === "customer") {
      this.dispatchGetCompanies();
    }
    this.dispatchGetProducts();
  },
  mounted() {
    this.$gtm.trackEvent({
      category: "Growth",
      action: "Login-success",
      label: "Login success",
      value: 0,
    });
  },
  methods: {
    dispatchGetCompanies() {
      this.$store
        .dispatch(GET_COMPANIES)
        .then(() => {})
        .catch((error) => {
          this.$root.$emit("errorMessage", error);
        });
    },
    dispatchGetProducts() {
      this.$store
        .dispatch(GET_PRODUCTS)
        .then(() => {})
        .catch((error) => {
          this.$root.$emit("errorMessage", error);
        });
    },
    onPaymentDetailsClick() {
      // console.log("onPaymentDetailsClick", this.user.subscriptions);
      // If User has no subscriptions send him to the product page
      if (
        this.user.subscriptions.searchSubscription === false &&
        this.user.subscriptions.contactSubscription === false
      ) {
        this.$router.push({ name: "pricing" });
      } else {
        this.$http.post("customer/session").then(
          (res) => {
            // console.log('res', res)
            window.location.href = res.data.url;
          },
          (err) => {
            console.error(err);
            this.$root.$emit("errorMessage", err.response);
          }
        );
      }
    },
    sendVerificationEmail() {
      this.verificationLoading = true;
      this.$store.dispatch(RESEND_EMAIL).then(
        () => {
          this.$root.$emit(
            "successMessage",
            "Verifieringsmail har skickats till din e-postadress"
          );
          this.verificationLoading = false;
          setTimeout(() => {
            this.verificationLoading = false;
          }, 1000);
        },
        (err) => {
          console.error("err", err);
          this.verificationLoading = false;
        }
      );
    },
    // TODO: Move to product page and pass in the priceId, rename to onProductClick
    onProductsCardClick(priceId) {
      // TODO: Refactor to use a more decisive way to find the "Sök & kontakta" product
      // Find product that contains string "kontakta" in the name
      const product = this.products.find((product) =>
        product.name.toLowerCase().includes("kontakta")
      );
      const payload = {
        priceId: product.priceId, // 'price_1H8Z2pJZ2Z2Z2Z2Z2Z2Z2Z2Z'
      };
      this.$http.post("checkout/session", payload).then(
        (res) => {
          window.location.href = res.data.url;
        },
        (err) => {
          console.error("err", err);
          this.$root.$emit("errorMessage", err.response);
        }
      );
    },
  },
};
</script>
